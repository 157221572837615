import { FC } from "react";
import { Text, Box, Flex, Heading, Button, Badge, useBreakpointValue } from "@chakra-ui/react";

import { GatsbyImage } from "gatsby-plugin-image";

import { IconBox, PrismicLink } from "src/atoms";
import { HEADER_CTA_CLICKED } from "../constants";
import { SvgOrImg } from "src/components/SvgOrImg";

const HeaderSectionButton: FC<{
  item: Exclude<
    ArrayElement<Gatsby.SiteNavbarDropdownPanelFragment["items"]>,
    undefined
  >;
  background: boolean;
  type: "primary" | "secondary" | "tertiary" | "tag";
}> = ({ item, background, type }) => {
  const size = useBreakpointValue({ base: 16, md: 24 }) || 16;

  const renderButton = () => {
    switch (type) {
      case "primary": // has icon, label and sub label
        return (
          <Flex align="flex-start" w="full">
            {item.icon?.document?.__typename === "PrismicElementImage" ? (
              <IconBox
                bg={{ base: "yellow-2", md: "greyLight-4" }}
                borderRadius={{ base: "md", md: "lg" }}
                w={{ base: "32px", md: "56px" }}
                h={{ base: "32px", md: "56px" }}
                marginRight={{ base: "space-8", md: "space-16" }}
              >
                <SvgOrImg
                  additionalBoxProps={{
                    maxW: { base: "16px", md: "24px"},
                    maxH: { base: "16px", md: "24px"}
                  }}
                  imageFile={item.icon.document.data?.image_file}
                  isBox={true}
                  size={size}
                />
              </IconBox>
            ) : null}
            <Box w="full">
              <Flex justify="space-between" w="full">
                <Heading
                  as="h3"
                  variant="nav-title"
                  lineHeight="short"
                  color="text-primary"
                  className="btn-heading"
                  fontFamily={{ base: "body", md: "heading" }}
                  mt={{ base: "6px" , md: 0}}
                >
                  {item.label}
                </Heading>

                {item.badge && (
                  <Badge
                    maxWidth="max-content"
                    height="fit-content"
                    variant="orange"
                    justifySelf={{ base: "flex-end" }}
                    paddingX={{ base: "space-8", md: "15px"}}
                    paddingY="space-8"
                    fontSize={{ base: "font-10", md: "font-12"}}
                    lineHeight="none"
                    textTransform="uppercase"
                    mt={{ base: "6px" , md: 0}}
                  >
                    {item.badge}
                  </Badge>
                )}
              </Flex>

              <Text
                display={{ base: "none", md: "block" }}
                mt="space-8"
                variant="body-small"
              >
                {item.sub_label}
              </Text>
            </Box>
          </Flex>
        );

      case "secondary": //has icon and label or label and sub label
        return (
          <Flex align="center">
            {item.icon?.document?.__typename === "PrismicElementImage" && (
              <Box
                as={GatsbyImage}
                boxSize={{ base: "32px", md: "48px" }}
                maxH={{ base: "32px", md: "48px" }}
                maxW={{ base: "32px", md: "48px" }}
                minW={{ base: "32px", md: "48px" }}
                marginRight={{ base: "space-8", md: "space-16" }}
                alt={item.icon.document.data?.image_file?.alt ?? ""}
                image={item.icon.document.data?.image_file?.gatsbyImageData}
              />
            )}
            <Box>
              <Heading
                variant="nav-title"
                lineHeight="short"
                color="text-primary"
                className="btn-heading"
                fontFamily={{ base: "body", md: "heading" }}
              >
                {item.label}
              </Heading>
              {item.sub_label && (
                <Text
                  display={{ base: "none", md: "block" }}
                  mt="space-8"
                  variant="body-small"
                >
                  {item.sub_label}
                </Text>
              )}
            </Box>
          </Flex>
        );
      case "tertiary": //only label
        return (
          <Flex align="center">
            <Box>
              <Text
                as="h3"
                lineHeight="short"
                className="btn-heading"
                variant="body-small"
                fontWeight="medium"
                fontFamily="body"
              >
                {item.label}
              </Text>
            </Box>
          </Flex>
        );

      case "tag": //only mobile tertiary link
      return (
        <Text
          as="div"
          py="space-4"
          px="space-8"
          borderRadius="md"
          bg="yellow-4"
          variant="body-small"
        >
          {item.label}
        </Text>
      );

      default:
        return null;
    }
  };

  return (
    <Button
      eventLabel={item.label}
      eventName={HEADER_CTA_CLICKED}
      as={PrismicLink}
      variant="ghost"
      link={item.link!}
      justifyContent="flex-start"
      paddingX="0"
      paddingY="0"
      w="full"
      param={item.link_param}
      _hover={{
        background: "transparent",
        opacity: background ? "0.5" : "1",
      }}
    >
      {renderButton()}
    </Button>
  );
};

export default HeaderSectionButton;
