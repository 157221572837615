import { FC } from "react";
import { List, Text, Box, BoxProps, Heading, Wrap } from "@chakra-ui/react";

import { PrismicLink } from "src/atoms";
import ShiButton from "src/atoms/ShiButton";

import HeaderSectionButton from "../components/HeaderSectionButton";
import { HEADER_CTA_CLICKED } from "../constants";

const HeaderSectionPanel: FC<
  {
    panel: Gatsby.SiteNavbarDropdownPanelFragment;
    background?: boolean;
  } & BoxProps
> = ({ panel, background, ...rest }) => {
  const { primary, items } = panel;

  const isLabelOnlyLinks = items?.every(
    (item) => !item?.icon?.document && !item?.sub_label,
  );

  return (
    <Box {...rest} w="full">
      {primary?.title && (
        <Box mb={{ base: "space-20", md: "space-24" }}>
          <Heading
            fontFamily="body"
            color="text-primary"
            fontSize="font-15"
            lineHeight="1.2"
            textTransform={{
              base: "uppercase",
              md: primary.sub_title ? "none" : "uppercase",
            }}
          >
            {primary.title_link?.url ? (
              <PrismicLink
                eventLabel={primary.title}
                eventName={HEADER_CTA_CLICKED}
                link={primary.title_link}
                _hover={{ color: "text-action" }}
                param={primary.title_link_param}
              >
                {primary.title}
              </PrismicLink>
            ) : (
              primary.title
            )}
          </Heading>
          {primary?.sub_title && (
            <Text
              display={{ base: "none", md: "block" }}
              mt="space-4"
              color="text-secondary"
              fontSize="font-13"
              lineHeight="1.29"
            >
              {primary.sub_title}
            </Text>
          )}
        </Box>
      )}
      <List
        spacing={{
          base: "space-8",
          md: "space-24",
        }}
        paddingLeft={{ base: "0", md: primary?.sub_title ? "space-16" : "0" }}
        display={{
          base: isLabelOnlyLinks ? "none" : "block",
          md: "block",
        }}
        w="full"
      >
        {items?.map((item, index) => {
          if (!item) return;

          const type =
            item.icon?.document && item.label && item.sub_label
              ? "primary"
              : (item.icon?.document && item.label) ||
                (item.label && item.sub_label)
              ? "secondary"
              : "tertiary";

          return (
            <li key={index}>
              <HeaderSectionButton
                item={item}
                background={background!}
                type={type}
              />
            </li>
          );
        })}
      </List>
      {isLabelOnlyLinks && (
        <Wrap display={{ md: "none" }}>
          {items?.map((item, index) => {
            if (!item) return;

            const type = "tag";

            return (
              <li key={index}>
                <HeaderSectionButton
                  item={item}
                  background={background!}
                  type={type}
                />
              </li>
            );
          })}
        </Wrap>
      )}
      {primary?.link && primary.cta_label && (
        <ShiButton
          as={PrismicLink}
          variant="link"
          link={primary.link}
          mt="space-24"
          fontSize="16px"
        >
          {primary.cta_label}
        </ShiButton>
      )}
    </Box>
  );
};

export default HeaderSectionPanel;
