import { FC } from "react";
import { Box, Button, Flex, VStack, Spacer, keyframes } from "@chakra-ui/react";
import { slide } from "../utils";
import useDropdownState from "../hook";
import { AnimatePresence, motion } from "framer-motion";
import { HeaderSection } from "../../../types";
import HeaderSectionPanel from "src/components/SiteNavbar/components/HeaderSectionPanel";
import Advertisement from "src/components/SiteNavbar/components/Advertisement";
import { NAVBAR_HEIGHT } from "src/slices/Header";
import { PrismicLink } from "src/atoms";
import { HEADER_CTA_CLICKED } from "src/components/SiteNavbar/constants";

const blurIn = keyframes({
  "0%": {
    backdropFilter: "blur(0px)",
    opacity: 0,
  },
  "100%": {
    backdropFilter: "blur(87px)",
    opacity: 1,
  },
});

const SiteHeaderDropdown: FC<{
  data: HeaderSection;
  menuIndex: number;
}> = ({ data: { primary, columns }, menuIndex }) => {
  const { ref, dispatch, isForceOpened, isSoftOpened } = useDropdownState({
    index: menuIndex,
  });

  if (!primary) return null;

  const getPanelWidth = (
    screenSize: string,
    isLarge: boolean,
    hasWhiteBackground?: boolean,
  ) => {
    if (isLarge) {
      return "566px";
    } else if (hasWhiteBackground === false) {
      return "244px";
    } else {
      return screenSize === "lg" ? "420px" : "320px";
    }
  };

  const renderColumns = (column: any, index: number, totalSections: number) => {
    switch (column?.__typename) {
      case "PrismicSiteNavbarDataBodyDropdownColumn":
        const isLarge =
          column.panels &&
          totalSections <= 2 &&
          column.panels.some((panel: Gatsby.SiteNavbarDropdownPanelFragment) =>
            panel?.items?.some(
              (item) => item?.icon?.document && item?.sub_label,
            ),
          );

        const hasWhiteBackground = column.primary?.white_background;

        return (
          <Flex
            key={index}
            paddingY={{ md: "space-20", lg: "space-40" }}
            paddingLeft={{ md: "space-20", lg: "space-40" }}
            paddingRight={
              index === totalSections - 1
                ? {
                    md: "space-20",
                    lg: "space-40",
                  }
                : 0
            }
            maxWidth={{
              md: getPanelWidth("md", isLarge, hasWhiteBackground),
              lg: getPanelWidth("lg", isLarge, hasWhiteBackground),
            }}
            w="full"
            marginLeft={
              !hasWhiteBackground && index !== 0
                ? { md: "space-20", lg: "space-40" }
                : 0
            }
            // flexShrink={{ xl: 0}}
            bg={column.primary?.white_background ? "white" : "greyLight-4"}
          >
            <VStack spacing="space-40" align="start">
              {column.panels.map(
                (
                  panel: Gatsby.SiteNavbarDropdownPanelFragment,
                  index: number,
                ) => {
                  return (
                    <HeaderSectionPanel
                      panel={panel}
                      background={column.primary?.white_background}
                      key={index}
                    />
                  );
                },
              )}
            </VStack>
          </Flex>
        );
      case "PrismicSiteNavbarDataBodyAdvertisement":
        return (
          <Flex
            key={index}
            padding={{ md: "space-20", lg: "space-40" }}
            width={{ xl: "472px" }}
            maxW={{ md: "320px", lg: "472px" }}
            flex={1}
            bg="white"
          >
            <Advertisement data={column} margin="0" />
          </Flex>
        );
      default:
        return null;
    }
  };

  const renderButtonContent = () => {
    if (primary.link?.url)
      return (
        <PrismicLink
          eventLabel={primary.title}
          eventName={HEADER_CTA_CLICKED}
          link={primary.link}
          param={primary.link_param}
        >
          {primary.title}
        </PrismicLink>
      );

    return primary?.title;
  };

  return (
    <Box>
      <Button
        ref={ref.buttonRef}
        variant="link-navbar"
        paddingX="space-4"
        paddingY="space-4"
        _first={{
          paddingLeft: 0,
        }}
        onMouseEnter={() =>
          dispatch({ type: "SOFT_OPENED", payload: menuIndex })
        }
        onMouseLeave={() => dispatch({ type: "SCHEDULE_CLOSE" })}
      >
        {renderButtonContent()}
      </Button>

      <Box ref={ref.popoverRef}>
        <AnimatePresence>
          {(isForceOpened || isSoftOpened) && (
            <motion.div
              transition={{
                duration: 0.25,
                easings: "easeOut",
              }}
              style={{ display: "flex" }}
              variants={slide}
              initial="exit"
              animate="enter"
              exit="exit"
            >
              <Spacer
                w={{ base: "0", md: "80px" }}
                onClick={() => dispatch({ type: "FORCE_CLOSE" })}
              />
              <Box
                transition="filter .2s ease-out"
                width="fit-content"
                borderBottomLeftRadius="lg"
                borderBottomRightRadius="lg"
                onMouseEnter={() =>
                  dispatch({ type: "CANCEL_SCHEDULED_CLOSE" })
                }
                onMouseLeave={() => dispatch({ type: "SCHEDULE_CLOSE" })}
                maxH={`calc(100vh - ${NAVBAR_HEIGHT}px)`}
                overflowY="auto"
                marginTop="-1px"
              >
                <Flex borderBottomRadius="lg" overflow="hidden" bg="white" >
                  {columns?.map((column, index) => {
                    if (!column) return null;
                    return renderColumns(column, index, columns.length);
                  })}
                </Flex>
              </Box>
            </motion.div>
          )}
        </AnimatePresence>
      </Box>
      <Box
        as={Box}
        position="fixed"
        top={`${NAVBAR_HEIGHT}px`}
        left="0"
        width="100vw"
        h={`calc(100vh - ${NAVBAR_HEIGHT}px)`}
        bg="rgba(222, 220, 206, 0.1)"
        visibility={isForceOpened || isSoftOpened ? "visible" : "hidden"}
        zIndex="-1"
        opacity="0"
        animation={
          isForceOpened || isSoftOpened
            ? `${blurIn} 0.2s forwards ease-out`
            : undefined
        }
      />
    </Box>
  );
};

export default SiteHeaderDropdown;
