import { Fragment } from "react";
import { graphql, Link as GatsbyLink, useStaticQuery } from "gatsby";
import { ShiIcon, Spacer } from "src/atoms";
import { Link } from "src/atoms/PrismicLink";
import ShiButton from "src/atoms/ShiButton";
import {
  Container,
  IconButton,
  Flex,
  useDisclosure,
  HStack,
} from "@chakra-ui/react";

import useHeaderDropdownState, { MenuContext } from "./state";
import { useInView } from "react-intersection-observer";
import SiteHeaderSection from "./slices/HeaderSection";
import MobileDrawer from "./components/MobileDrawer";

import { groupSections } from "./utils";
import SiteBanner from "./components/SiteBanner";
import { onClickNewAccount } from "src/lib/event";
import useVisitorContext from "src/providers/VisitorContext/useVisitorContext";
import { useLocation } from "@reach/router";
import { ClickInfo } from "src/lib/event/types";

const SiteNavbar = () => {
  const navbarQuery = useStaticQuery<Gatsby.SiteNavbarDataQuery>(graphql`
    query SiteNavbarData {
      prismicSiteNavbar {
        ...SiteNavbar
      }
    }
  `);

  const data = navbarQuery.prismicSiteNavbar?.data;

  if (!data) throw Error();

  const visitorContext = useVisitorContext();
  const location = useLocation();

  const { ref, inView: scrollTop } = useInView({
    initialInView: true,
    rootMargin: "0px",
  });

  const drawer = useDisclosure();
  const { state, dispatch } = useHeaderDropdownState();

  const banner: any = data.body?.find(
    (slice) => slice?.__typename === "PrismicSiteNavbarDataBodySiteBanner",
  );
  const headerSections = groupSections(data);

  return (
    <Fragment>
      {/* Used to know if the user has scrolled */}
      <div ref={ref} />

      <Container
        position="sticky"
        size="full"
        top={0}
        background="white"
        zIndex="navbar"
        id="site-navbar"
      >
        {<SiteBanner open={scrollTop} {...banner} />}
        <MobileDrawer
          isOpen={drawer.isOpen}
          onClose={drawer.onClose}
          data={data}
          headerSections={headerSections!}
        />
        <Container
          as="nav"
          alignItems="flex-start"
          display="flex"
          justifyContent="space-between"
          paddingY="space-8"
          size="block"
        >
          <IconButton
            as={GatsbyLink}
            aria-label="Shine logo"
            variant="ghost"
            marginRight={{ base: "space-24", lg: "space-40" }}
            marginY="auto"
            paddingY="0"
            icon={
              <ShiIcon
                name="ShineLogo"
                width={{ base: "70px", md: "96px" }}
                height="auto"
              />
            }
            to="/"
            _hover={{
              backgroundColor: "initial",
            }}
          />

          <IconButton
            display={{ base: "flex", md: "none" }}
            aria-label="menu"
            icon={<ShiIcon name="menu" w="20px" h="20px" />}
            onClick={drawer.onOpen}
            variant="icon"
            width="32px"
            height="32px"
            minW="unset" //override default style
            marginRight="-8px" //to align with right end
          />

          <Flex
            alignItems="center"
            flexGrow={1}
            display={{ base: "none", md: "flex" }}
          >
            <MenuContext.Provider value={{ state, dispatch }}>
              <HStack
                spacing={{ base: "space-24", lg: "space-40" }}
                fontSize="font-15"
              >
                {headerSections?.map((slice, index) => {
                  return (
                    <SiteHeaderSection
                      key={index}
                      data={slice}
                      menuIndex={index}
                    />
                  );
                })}
              </HStack>
            </MenuContext.Provider>

            <Spacer />

            <Link
              fontWeight="medium"
              fontSize="font-15"
              variant="link-secondary"
              href="https://app.shine.fr/"
              marginLeft={{ base: "space-16", lg: "space-24" }}
            >
              {data.login_label}
            </Link>
            <ShiButton
              onClick={(e) => {
                const clickInfo: ClickInfo = {
                  label: data.start_label,
                  platform: 'web-desktop',
                  block: 'site_navbar',
                  fromLocation: location
                };

                onClickNewAccount(e, visitorContext, clickInfo)
              }}
              variant="yellow"
              size="md"
              fontSize="font-15"
              marginLeft={{ base: "space-16", lg: "space-40" }}
            >
              {data.start_label}
            </ShiButton>
          </Flex>
        </Container>
      </Container>
    </Fragment>
  );
};

export default SiteNavbar;
