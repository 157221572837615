import { HeaderSection } from "./types"

export const groupSections = ({ body }: Gatsby.SiteNavbarBodyFragment) => {
  const groupedSections = body?.reduce((sections, curr) => {
    //prisimic sections types identify its nested level and are indexed by its order
    const currentSection = sections[sections.length - 1];

    if (curr?.__typename === "PrismicSiteNavbarDataBodyHeaderSection") {
      //Appearance of the header section(top level): indicates the beginning of new top level section,
      //and will nest any 2nd level items until the apprearance of new header section
      sections.push(curr);
    }

    //Dropdown column and advertisement (2nd level): are nested inside the current top level section
    // and will nest any 3rd level items until the appearance of new 2nd level section

    //length of top level sections will not change in this step, but the length of section columns will change in this step
    if (
      curr?.__typename === "PrismicSiteNavbarDataBodyDropdownColumn" ||
      curr?.__typename === "PrismicSiteNavbarDataBodyAdvertisement"
    ) {
      if (currentSection) {
        if (currentSection.columns && !currentSection.columns.includes(curr)) {
          currentSection.columns = [...currentSection.columns, curr]; //if current section has exisiting columns array, insert itself
        } else {
          currentSection.columns = [curr]; // if current section has no nested columns array, create columns array
        }
      }
    }

    //Dropdown panel (3rd level): is nested in column(2nd level)
    //length of top level sections or current section's columns will not change, but the length of current section's current column panels will change
    if (curr?.__typename === "PrismicSiteNavbarDataBodyDropdownPanel") {
      const currentColumn =
        currentSection.columns &&
        currentSection.columns[currentSection.columns?.length - 1];

      if (currentColumn) {
        if (currentColumn.panels && !currentColumn.panels.includes(curr)) {
          currentColumn.panels = [...currentColumn.panels, curr]; //if current column has existing panels, insert itset
        } else {
          currentColumn.panels = [curr]; //if current section has no nested panels array, create panels array
        }
      }
    }

    return sections;
  }, [] as HeaderSection[]);

  return groupedSections;
};
