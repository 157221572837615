import {
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  VStack,
  Heading
} from "@chakra-ui/react";
import { FC, Fragment } from "react";
import { HeaderSection } from "../types";
import Advertisement from "./Advertisement";
import HeaderSectionPanel from "./HeaderSectionPanel";
import { ShiIcon } from "src/atoms";

const HeaderAccordionItem: FC<{ section: HeaderSection }> = ({ section }) => {
  const renderColumns = (column: any) => {
    switch (column?.__typename) {
      case "PrismicSiteNavbarDataBodyAdvertisement":
        return <Advertisement data={column} display={{base: "none", md: "block"}} />;
      case "PrismicSiteNavbarDataBodyDropdownColumn":
        const { primary, panels } = column;

        return (
          <Box paddingX="0" paddingY="space-12">
            <VStack spacing="space-24" align="flex-start">
              {panels?.map((panel: Gatsby.SiteNavbarDropdownPanelFragment) => {
                return (
                  <HeaderSectionPanel
                    panel={panel}
                    key={panel.id}
                    background={primary?.white_background}
                  />
                );
              })}
            </VStack>
          </Box>
        );
      default:
        return null;
    }
  };
  return (
    <AccordionItem border="none">
      {({ isExpanded }) => (
        <Fragment>
          <AccordionButton
            justifyContent="space-between"
            paddingY="space-12"
            paddingX="0"
            color="text-primary"
            _hover={{ bg: "yellow-0" }}
          >
            <Heading as="div" variant="subtitle">
              {section.primary?.title}
            </Heading>
            <ShiIcon
              name="chevron"
              width="24px"
              transform={isExpanded ? "rotate(180deg)" : "rotate(0deg)"}
            />
          </AccordionButton>

          <AccordionPanel p="0">
            {section.columns?.map((column, index) => {
              return <Fragment key={index}>{renderColumns(column)}</Fragment>;
            })}
          </AccordionPanel>
        </Fragment>
      )}
    </AccordionItem>
  );
};

export default HeaderAccordionItem;
