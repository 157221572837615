import { Box, Badge, BoxProps, Heading, Text, VStack } from "@chakra-ui/react";

import { FC } from "react";
import ShiButton from "src/atoms/ShiButton";
import { GatsbyImage } from "gatsby-plugin-image";

import PrismicLink from "src/atoms/PrismicLink";
import { HEADER_CTA_CLICKED } from "../constants";

const Advertisement: FC<
  { data: Gatsby.SiteNavbarAdvertisementFragment } & BoxProps
> = ({ data, ...rest }) => {
  if (!data) return null;

  const { primary } = data;

  return (
    <Box
      mx="space-8"
      mt={{ base: "space-24", md: "unset" }}
      borderRadius="lg"
      overflow="hidden"
      position="relative"
      h="min"
      bg="greyLight-4"
      sx={{
        ":hover": {
          ".element-cta-icon": {
            transform: (theme) => `translateX(${theme.space[1]})`,
          },
        },
      }}
      {...rest}
    >
      {primary?.link && (
        <PrismicLink
          eventLabel={primary?.cta_label}
          eventName={HEADER_CTA_CLICKED}
          link={primary.link}
        >
          <VStack
            align="start"
            padding="space-40"
            w="full"
            h="full"
            spacing="space-16"
          >
            {primary?.badge && (
              <Badge variant="orange" textTransform="uppercase">
                {primary.badge}
              </Badge>
            )}
            {primary?.image?.document && (
              <Box w="full">
                <Box
                  as={GatsbyImage}
                  image={
                    primary.image?.document?.data?.image_file?.gatsbyImageData
                  }
                  alt={primary.image?.document?.data?.image_file?.alt ?? ""}
                  mx="auto"
                />
              </Box>
            )}
            {primary?.title && (
              <Heading fontSize="font-24" lineHeight="short">
                {primary.title}
              </Heading>
            )}
            {primary?.sub_title && (
              <Text variant="body-small">{primary.sub_title}</Text>
            )}

            {primary?.cta_label && (
              <ShiButton
                as="div"
                variant="primary"
                fontSize="font-13"
                className="navbar-ad-ctaBtn"
              >
                {primary?.cta_label}
              </ShiButton>
            )}
          </VStack>
        </PrismicLink>
      )}
    </Box>
  );
};

export default Advertisement;
