import {
  Accordion,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Heading,
  IconButton,
  useBreakpointValue,
  UseDisclosureReturn,
  VStack,
} from "@chakra-ui/react";

import { ShiIcon, PrismicLink } from "src/atoms";
import { Link } from "src/atoms/PrismicLink";
import ShiButton from "src/atoms/ShiButton";
import HeaderAccordionItem from "./HeaderAccordionItem";
import { onClickNewAccount } from "src/lib/event";
import useVisitorContext from "src/providers/VisitorContext/useVisitorContext";
import { useLocation } from "@reach/router";
import { ClickInfo } from "src/lib/event/types";

import { FC, Fragment } from "react";

import { HeaderSection } from "../types";
import { HEADER_CTA_CLICKED } from "../constants";

type MobileDrawerProps = Pick<UseDisclosureReturn, "isOpen" | "onClose"> & {
  data: Gatsby.SiteNavbarFragment["data"];
  headerSections: HeaderSection[];
};

const MobileDrawer: FC<MobileDrawerProps> = ({
  isOpen,
  onClose,
  data,
  headerSections,
}) => {
  if (!data) throw Error();

  const size = useBreakpointValue({ base: "full", sm: "md" });
  const visitorContext = useVisitorContext();
  const location = useLocation();

  return (
    <Drawer isOpen={isOpen} onClose={onClose} size={size}>
      <DrawerOverlay />
      <DrawerContent
        height="100dvh"
      >
        <DrawerHeader
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          paddingY="space-8"
          paddingX="space-16"
        >
          <IconButton
            aria-label="Shine logo"
            variant="ghost"
            marginRight="space-24"
            marginLeft="2px"
            icon={
              <ShiIcon
                name="ShineLogo"
                width={{ base: "70px" }}
                height="auto"
              />
            }
          />
          <DrawerCloseButton
            size="md"
            position="unset"
            sx={{
              svg: {
                width: "16px",
                height: "16px",
              },
            }}
          />
        </DrawerHeader>

        <DrawerBody
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          margin="space-16"
          bg="yellow-0"
          borderRadius="lg"
          paddingX="space-24"
          paddingY="space-28"
        >
          <Accordion
            textAlign="left"
            width="100%"
            allowToggle
            sx={{
              "> :first-of-type": {
                borderTop: "none",
              },
              "> .chakra-link": {
                display: "block",
                paddingY: "space-12",
              },
            }}
          >
            {headerSections?.map((section) => {
              if (!section || !section.primary) return;

              return (
                <Fragment key={section.id}>
                  {!section.columns ? (
                    <PrismicLink
                      eventLabel={section.primary.title}
                      eventName={HEADER_CTA_CLICKED}
                      link={section.primary.link}
                      param={section.primary.link_param}
                    >
                      <Heading as="div" variant="subtitle">
                        {section.primary.title}
                      </Heading>
                    </PrismicLink>
                  ) : (
                    <HeaderAccordionItem key={section.id} section={section!} />
                  )}
                </Fragment>
              );
            })}
          </Accordion>
          <VStack spacing="space-24">
            <ShiButton
              onClick={(e) => {
                const clickInfo: ClickInfo = {
                  label: data.start_label,
                  platform: 'web-mobile',
                  block: 'site_navbar',
                  fromLocation: location
                };

                onClickNewAccount(e, visitorContext, clickInfo)
              }}
              variant="primary"
              size="md"
              w="full"
              hasArrow
            >
              {data.start_label}
            </ShiButton>

            <ShiButton
              as={Link}
              variant="secondary"
              href="https://app.shine.fr/"
            >
              {data.login_label}
            </ShiButton>
          </VStack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default MobileDrawer;
